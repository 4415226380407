<template>
  <div></div>
</template>

<script>
import encryptCircleData from '@/utils/encrypt-circle-data';

export default {
  name: 'DepositEncrypt',

  mounted() {
    window.addEventListener('flutterInAppWebViewPlatformReady', this.encryptHandle);
  },

  beforeDestroy() {
    window.removeEventListener('flutterInAppWebViewPlatformReady', this.encryptHandle);
  },

  methods: {
    encryptHandle() {
      window.flutter_inappwebview.callHandler('encryptHandle')
      .then(async ({ data, publicKey }) => {
        try {
          const encryptedCardData = await encryptCircleData(publicKey, data);

          this.nativeAppPostMessage({
            success: true,
            data: encryptedCardData,
          });
        } catch (err) {
          this.nativeAppPostMessage({
            success: false,
            message: 'failed_to_encrypt',
            err,
          });
        }
      });
    }
  }
};
</script>

<style scoped>
</style>
